import { UseCaseError } from '@digibricks/domains';
import { CreatedFileStorage } from '@module/file-storage';
import { SubmissionModalStatus, SubmissionPageStatus } from './submission.reducer';
import {
  CreateAnswerUseCaseInput,
  CreateEssayAnswerInput,
  CreateFillInBlankAnswerInput,
  CreateMultipleChoiceAnswerInput,
  CreateSingleChoiceAnswerInput,
  EssayAnswer,
  ExaminationAssignment,
  FillInBlankGroupAnswer,
  FindDraftEssayAnswerInput,
  HomeworkAssignment,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  PracticeAssignment,
  SingleChoiceAnswer,
  SubmissionActivity,
  SubmissionSummary,
  UpdateDraftEssayAnswerInput,
  UpdateEssayAnswerUseCaseInput,
} from '@module/assignment';
import {
  AudioFile,
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
  SectionSummary,
} from '@module/form';
import { RequestStatus } from '@lib/plugin-redux-core';

export type SubmissionPageInitialState = {
  error?: UseCaseError<any>;
  questionAnswered: {
    sectionId: string;
    questionId: string;
    submissionId: string;
  }[];
  selectedFile: CreatedFileStorage | File;
  selectedSectionIndex: number;
};

type GetAssignmentByIdUseCaseState = {
  getAssignmentStatus: SubmissionPageStatus;
  assignment: PracticeAssignment | ExaminationAssignment | HomeworkAssignment;
  isLoadingGetAssignmentById: boolean;
};

type GetFromReleaseByIdUseCaseState = {
  getFromReleaseStatus: SubmissionPageStatus;
  formRelease: FormRelease;
};

type DeleteSubmissionAnswerFile = {
  deleteSubmissionAnswerFileStatus: SubmissionPageStatus;
};

type GetAnswersUseCaseState = {
  getAnswersStatus: SubmissionPageStatus;
  submissionAnswers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
    | MatchingAnswerGroup
  )[];
};

export type ChangeSelectedSection = {
  selectedSection: SectionSummary;
  selectedSectionIndex: number;
};

type UpdateSectionSelectedUseCaseState = {
  selectedSection: SectionSummary;
  updateSelectedSectionStatus: SubmissionPageStatus;
};

type GetAnsweredQuestionsState = {
  answeredQuestionIds: string[];
  getAnsweredQuestionsStatus: SubmissionPageStatus;
};

type GetQuestionReleasesUseCaseState = {
  getQuestionReleasesStatus: SubmissionPageStatus;
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
};

type CreateAnswerUseCaseState = {
  createAnswerStatus: SubmissionPageStatus;
};

type UpdateAnswerUseCaseState = {
  updateAnswerStatus: RequestStatus;
  updateEssayAnswerStatus: RequestStatus;
  updateAnswerInput: UpdateEssayAnswerUseCaseInput;
  updateFileAnswerStatus: RequestStatus;
  updateAudioFileAnswerStatus: RequestStatus;
};

type UploadAnswerAudioUseCaseState = {
  uploadAudioStatus: SubmissionPageStatus;
};

export type CreateSubmissionAnswerFileUseCaseState = {
  createSubmissionAnswerFileResponse: CreatedFileStorage;
  createSubmissionAnswerFileStatus: SubmissionPageStatus;
};

type GetResponderSubmissionDetailUseCaseState = {
  getSubmissionStatus: SubmissionPageStatus;
  submission: SubmissionSummary;
  isLoadingSubmissions: boolean;
};
type GetResponderSubmissionActivityUseCaseState = {
  getSubmissionActivityStatus: SubmissionPageStatus;
  submissionActivity: SubmissionActivity;
  isLoadingSubmissionActivity: boolean;
};

type SubmitSubmissionUseCaseState = {
  submitSubmissionStatus: RequestStatus;
};

type PausePracticeSubmissionUseCaseState = {
  pausePracticeSubmissionStatus: SubmissionPageStatus;
};

type ResumePracticeSubmissionUseCaseState = {
  resumePracticeSubmissionStatus: SubmissionPageStatus;
  isLoadingResumePracticeSubmission: boolean;
};

type SetSubmitPracticeSubmissionTimeState = {
  setSubmitPracticeSubmissionTimeStatus: SubmissionPageStatus;
};

type SetStopCountDownSubmissionStatusState = {
  isCountingDown: boolean;
};

type SetIsEndTimePracticeSubmissionState = {
  setIsEndTimePracticeSubmissionStatus: SubmissionModalStatus;
};

type CreateSingleChoiceAnswerState = {
  createSingleChoiceAnswerInput: CreateAnswerUseCaseInput<CreateSingleChoiceAnswerInput>;
};

type CreateMultipleChoiceAnswerState = {
  createMultipleChoiceAnswerInput: CreateAnswerUseCaseInput<CreateMultipleChoiceAnswerInput>;
};

type CreateFillInBlankAnswerState = {
  createFillInBlankAnswerInput: CreateAnswerUseCaseInput<CreateFillInBlankAnswerInput>;
};

type CreateEssayAnswerState = {
  createEssayAnswerInput: CreateAnswerUseCaseInput<CreateEssayAnswerInput>;
};

type GetRecordedElapsedTimeState = {
  recordedElapsedTime: number;
  getRecordedElapsedTimeStatus: SubmissionPageStatus;
};

type GetFromReleaseBySubmissionUseCase = {
  getFormReleaseBySubmissionStatus: SubmissionPageStatus;
};

type SetIsOpenEndTimeModalState = {
  isOpenEndTimeModal: boolean;
};

type SetIsAutoPlayAudioSection = {
  isAutoPlayAudioSection: boolean;
};

type UpdateCurrentPlayingAudio = {
  currentPlayingAudio: {
    id: string;
  };
};

export type UpdateHomeworkSubmissionUseCaseState = {
  updateHomeworkSubmissionStatus: RequestStatus;
};

export type UpdateExaminationSubmissionUseCaseState = {
  updateExaminationSubmissionStatus: RequestStatus;
};

export type UpdatePracticeSubmissionUseCaseState = {
  updatePracticeSubmissionStatus: RequestStatus;
};

export type SubmissionAudio = {
  id: string;
  player: HTMLAudioElement;

  numberOfReplays: number;
  progress: number;
  duration: number;
  currentTime: number;
  volume: number;

  isAutoPlayAudioSection: boolean;
  isLoading: boolean;
  isPlaying: boolean;
  isEnded: boolean;
  isDisplayPlayPause: boolean;
  isHideAudioPlayer: boolean;
  isShowHeaderVolume: boolean;
};

export type SubmissionAudioVersion = {
  submissionAudiosVersion: number;
};

export type SubmissionAudiosState = {
  submissionAudios: SubmissionAudio[];
};

type FindDraftEssayAnswerUseCaseState = {
  findDraftEssayAnswerStatus: RequestStatus;
  draftEssayAnswers: Array<UpdateDraftEssayAnswerInput>;
};

type UpdateDraftEssayAnswerUseCaseState = {
  updateDraftEssayAnswerStatus: RequestStatus;
};
type SetIsOpenSwitchSectionAudioModal = {
  isConfirmSwitchSection: boolean;
};

export type SubmissionPageState = SubmissionPageInitialState &
  GetAssignmentByIdUseCaseState &
  GetFromReleaseByIdUseCaseState &
  DeleteSubmissionAnswerFile &
  GetQuestionReleasesUseCaseState &
  GetResponderSubmissionDetailUseCaseState &
  GetAnswersUseCaseState &
  UpdateSectionSelectedUseCaseState &
  PausePracticeSubmissionUseCaseState &
  ResumePracticeSubmissionUseCaseState &
  CreateAnswerUseCaseState &
  UpdateAnswerUseCaseState &
  UploadAnswerAudioUseCaseState &
  SubmitSubmissionUseCaseState &
  SetSubmitPracticeSubmissionTimeState &
  SetStopCountDownSubmissionStatusState &
  SetIsEndTimePracticeSubmissionState &
  CreateSingleChoiceAnswerState &
  CreateMultipleChoiceAnswerState &
  CreateFillInBlankAnswerState &
  CreateEssayAnswerState &
  CreateSubmissionAnswerFileUseCaseState &
  GetRecordedElapsedTimeState &
  GetFromReleaseBySubmissionUseCase &
  SetIsOpenEndTimeModalState &
  GetAnsweredQuestionsState &
  SetIsAutoPlayAudioSection &
  UpdateCurrentPlayingAudio &
  UpdateHomeworkSubmissionUseCaseState &
  UpdateExaminationSubmissionUseCaseState &
  SubmissionAudiosState &
  GetResponderSubmissionActivityUseCaseState &
  GetResponderSubmissionActivityUseCaseState &
  FindDraftEssayAnswerUseCaseState &
  UpdatePracticeSubmissionUseCaseState &
  SetIsOpenSwitchSectionAudioModal &
  SubmissionAudioVersion &
  UpdateDraftEssayAnswerUseCaseState;

export const initialState: SubmissionPageInitialState = {
  error: null,
  questionAnswered: [],
  selectedFile: null,
  selectedSectionIndex: 0,
};
