import { Action, RequestStatus } from '@lib/plugin-redux-core';
import {
  ConfigType,
  EssayAnswer,
  SubmissionSummary,
  UpdateEssayAnswerUseCaseInput,
  submissionStatus,
  ConfigTypeProps,
  FillInBlankGroupAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  AnswerType,
  UpdateMultipleChoiceAnswerUseCaseInput,
  UpdateSingleChoiceAnswerUseCaseInput,
  UpdateFillInBlankAnswerUseCaseInput,
  SingleMultipleChoiceAnswerValue,
  FillInBlankAnswerValue,
  UpdateEssayAnswerUseCaseOutput,
  DeleteEssayAnswerFileUseCaseOutput,
  UpdateMatchingAnswerUseCaseInput,
  MatchingAnswerGroup,
  MatchingAnswerValue,
  UpdateDraftEssayAnswerInput,
  UploadAnswerAudioUseCase,
  UploadAnswerAudioUseCaseOutput,
} from '@module/assignment';
import { SubmissionPageStatus } from './submission.reducer';
import { CreatedFileStorage } from '@module/file-storage';
import { SubmissionAudio, SubmissionPageState } from './submission.state';
import { QuestionReleases } from '@module/form';

interface UpdateDraftEssayAnswerProps {
  draftEssayAnswers: Array<UpdateDraftEssayAnswerInput>;
  submissionId: string;
  questionId: string;
  content: string;
}

const onUpdateStatusSubmission = (
  submission: SubmissionSummary,
  status: ConfigTypeProps,
): SubmissionSummary => {
  submission.updateStatus(ConfigType.create(status).getValue());

  return submission;
};

const updateAnswerList = (
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
    | MatchingAnswerGroup
  )[],
  answer: EssayAnswer,
): (
  | EssayAnswer
  | SingleChoiceAnswer
  | MultipleChoiceAnswer
  | FillInBlankGroupAnswer
  | MatchingAnswerGroup
)[] =>
  answers.map((answerItem) => {
    const isEssayAnswer = answerItem.type === AnswerType.Essay;
    if (isEssayAnswer) {
      const essayAnswer = answerItem as EssayAnswer;
      const isMatchedAnswer = essayAnswer.id.toString() === answer.id.toString();

      if (isMatchedAnswer) {
        return answer;
      }
    }

    return answerItem;
  });

const updateSubmissionAudios = (submissionAudios: SubmissionAudio[], payload: SubmissionAudio) => {
  const updatedAudios = submissionAudios ? [...submissionAudios] : [];

  if (!payload?.id) {
    return submissionAudios;
  }

  const existingAudioIndex = updatedAudios.findIndex(
    (submissionAudio) => submissionAudio.id === payload.id,
  );

  if (existingAudioIndex !== -1) {
    updatedAudios[existingAudioIndex] = {
      ...updatedAudios[existingAudioIndex],
      ...payload,
    };
  } else {
    updatedAudios.push(payload);
  }

  return updatedAudios;
};

const updateDraftEssayAnswer = ({
  draftEssayAnswers,
  submissionId,
  questionId,
  content,
}: UpdateDraftEssayAnswerProps) => {
  const updatingDraftEssayAnswer = draftEssayAnswers || [];

  const draftEssayAnswerIndex = updatingDraftEssayAnswer.findIndex(
    (item) => item.submissionId === submissionId && item.questionId === questionId,
  );

  if (draftEssayAnswerIndex >= 0) {
    const existingAnswer = updatingDraftEssayAnswer[draftEssayAnswerIndex];
    if (existingAnswer.content !== content) {
      existingAnswer.content = content;
    }
  } else {
    updatingDraftEssayAnswer.push({
      content,
      questionId,
      submissionId,
    });
  }

  return updatingDraftEssayAnswer;
};
export const handleChangeSectionUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.EXECUTE,
      selectedSectionIndex: payload.selectedSectionIndex,
      selectedSection: state.formRelease.getSectionByIndex(payload.selectedSectionIndex),
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

export const handleGetAnswersUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const submissionAnswers = action.payload as (
      | EssayAnswer
      | SingleChoiceAnswer
      | MultipleChoiceAnswer
      | FillInBlankGroupAnswer
      | MatchingAnswerGroup
    )[];
    let answeredQuestionIds = [];
    submissionAnswers.forEach((answer) => {
      switch (answer.type) {
        case AnswerType.Matching: {
          const matchingAnswer = answer as MatchingAnswerGroup;
          const answersDone = matchingAnswer.answers.filter((a) => a.value);
          if (answersDone?.length > 0) {
            const listAnswerIds = answersDone.map(
              (a) => `${a.subQuestionId}_${a.questionId}_${a.sectionId}`,
            );
            answeredQuestionIds.push(...listAnswerIds);
          }
          return answeredQuestionIds;
        }
        case AnswerType.FillInBlank: {
          const fillInBlankGroupAnswer = answer as FillInBlankGroupAnswer;
          const answersDone = fillInBlankGroupAnswer.answers.filter(
            (a) => a.value && a.value.text !== '',
          );
          if (answersDone?.length > 0) {
            const listAnswerIds = answersDone.map(
              (a) => `${a.answerBlankId}_${a.questionId}_${a.sectionId}`,
            );
            answeredQuestionIds.push(...listAnswerIds);
          }
          return answeredQuestionIds;
        }
        case AnswerType.Essay: {
          const essayAnswer = answer as EssayAnswer;
          if (essayAnswer.value?.text && essayAnswer.value?.text !== '') {
            answeredQuestionIds.push(
              `${essayAnswer.id.toString()}_${essayAnswer.questionId}_${essayAnswer.sectionId}`,
            );
          }
          return answeredQuestionIds;
        }
        case AnswerType.SingleChoice: {
          const singleChoiceAnswer = answer as SingleChoiceAnswer;

          if (singleChoiceAnswer.value?.answerId) {
            answeredQuestionIds
              // eslint-disable-next-line max-len
              .push(
                `${singleChoiceAnswer.id.toString()}_${singleChoiceAnswer.questionId}_${
                  singleChoiceAnswer.sectionId
                }`,
              );
          }
          return answeredQuestionIds;
        }
        case AnswerType.MultipleChoice: {
          const multipleChoiceAnswer = answer as MultipleChoiceAnswer;
          if (multipleChoiceAnswer.values?.length > 0) {
            answeredQuestionIds
              // eslint-disable-next-line max-len
              .push(
                `${multipleChoiceAnswer.id.toString()}_${multipleChoiceAnswer.questionId}_${
                  multipleChoiceAnswer.sectionId
                }`,
              );
          }
          return answeredQuestionIds;
        }
      }
    });

    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.SUCCESS,
      answeredQuestionIds,
      submissionAnswers,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleUpdateEssayAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as UpdateEssayAnswerUseCaseInput;
    const updatedEssayAnswer = state.submissionAnswers.find(
      (answer) =>
        answer.type === AnswerType.Essay &&
        (answer as EssayAnswer).id.toString() === useCaseInput.id,
    ) as EssayAnswer;

    let hasNotAnsweredBefore = false;
    if (
      !state.answeredQuestionIds.includes(
        `${updatedEssayAnswer.id.toString()}_${updatedEssayAnswer.questionId}_${
          updatedEssayAnswer.sectionId
        }`,
      )
    ) {
      hasNotAnsweredBefore = true;
    }

    const updatedState: SubmissionPageState = {
      ...state,
      updateAnswerInput: useCaseInput,
      updateAnswerStatus: RequestStatus.EXECUTE,
      updateEssayAnswerStatus: RequestStatus.EXECUTE,
    };

    if (hasNotAnsweredBefore) {
      updatedState.answeredQuestionIds.push(
        `${updatedEssayAnswer.id.toString()}_${useCaseInput.input.questionId}_${
          useCaseInput.input.sectionId
        }`,
      );

      return updatedState;
    }

    return updatedState;
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { answer } = action.payload as UpdateEssayAnswerUseCaseOutput;
    let answeredQuestionIds: string[];
    if (answer.isSkipped()) {
      answeredQuestionIds = state.answeredQuestionIds.filter((answeredQuestion) => {
        const [answerId, questionId, sectionId] = answeredQuestion.split('_');

        return (
          `${answerId}_${questionId}_${sectionId}` !==
          `${answer.id.toString()}_${answer.questionId}_${answer.sectionId}`
        );
      });
    }

    const updatedAnswerList = updateAnswerList(state.submissionAnswers, answer);

    //TODO: Refactor to make clean later after MVP
    const updatingDraftEssayAnswer = updateDraftEssayAnswer({
      draftEssayAnswers: state.draftEssayAnswers,
      submissionId: state.updateAnswerInput.submissionId,
      questionId: state.updateAnswerInput.input.questionId,
      content: null,
    });

    const commonStateUpdates = {
      ...state,
      updateAnswerStatus: RequestStatus.SUCCESS,
      submissionAnswers: updatedAnswerList,
      answeredQuestionIds: answeredQuestionIds ? answeredQuestionIds : state.answeredQuestionIds,
      draftEssayAnswers: updatingDraftEssayAnswer,
    };

    if (state.updateAnswerInput.input.value.files) {
      return {
        ...commonStateUpdates,
        updateFileAnswerStatus: RequestStatus.SUCCESS,
      };
    }
    if (state.updateAnswerInput.input.value.audioFile) {
      return {
        ...commonStateUpdates,
        updateAudioFileAnswerStatus: RequestStatus.SUCCESS,
      };
    }

    return {
      ...commonStateUpdates,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateAnswerStatus: RequestStatus.ERROR,
      updateEssayAnswerStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      updateAnswerStatus: RequestStatus.RESET,
      updateEssayAnswerStatus: RequestStatus.RESET,
      updateFileAnswerStatus: RequestStatus.RESET,
      updateAudioFileAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUploadAnswerAudio = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      uploadAudioStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { answer } = action.payload as UploadAnswerAudioUseCaseOutput;
    const updatedAnswerList = updateAnswerList(state.submissionAnswers, answer);

    let answeredQuestionIds: string[];
    if (answer.isSkipped()) {
      answeredQuestionIds = state.answeredQuestionIds.filter((answeredQuestion) => {
        const [answerId, questionId, sectionId] = answeredQuestion.split('_');

        return (
          `${answerId}_${questionId}_${sectionId}` !==
          `${answer.id.toString()}_${answer.questionId}_${answer.sectionId}`
        );
      });
    }

    return {
      ...state,
      uploadAudioStatus: SubmissionPageStatus.SUCCESS,
      submissionAnswers: updatedAnswerList,
      answeredQuestionIds: answeredQuestionIds ? answeredQuestionIds : state.answeredQuestionIds,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      uploadAudioStatus: SubmissionPageStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      uploadAudioStatus: SubmissionPageStatus.RESET,
    };
  },
};

export const handleUpdateSingleChoiceAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as UpdateSingleChoiceAnswerUseCaseInput;

    const updatedState: SubmissionPageState = {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
    };

    const submissionAnswers = state.submissionAnswers.map((submissionAnswer) => {
      const isMatchedAnswer =
        submissionAnswer instanceof SingleChoiceAnswer &&
        submissionAnswer.id.toString() === useCaseInput.id;

      if (isMatchedAnswer) {
        const { input } = useCaseInput;
        const { value } = input;

        submissionAnswer.updateValue(SingleMultipleChoiceAnswerValue.create(value).getValue());

        const hasNotAnsweredBefore = !state.answeredQuestionIds.includes(
          `${useCaseInput.id.toString()}_${useCaseInput.input.questionId}_${
            useCaseInput.input.sectionId
          }`,
        );
        if (hasNotAnsweredBefore) {
          updatedState.answeredQuestionIds.push(
            `${useCaseInput.id.toString()}_${useCaseInput.input.questionId}_${
              useCaseInput.input.sectionId
            }`,
          );
        }
      }

      return submissionAnswer;
    });

    updatedState.submissionAnswers = submissionAnswers;

    return updatedState;
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.ERROR };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      updateAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateMultipleChoiceAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as UpdateMultipleChoiceAnswerUseCaseInput;

    const updatedState: SubmissionPageState = {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
    };

    const submissionAnswers = state.submissionAnswers.map((submissionAnswer) => {
      const isMatchedAnswer =
        submissionAnswer instanceof MultipleChoiceAnswer &&
        submissionAnswer.id.toString() === useCaseInput.id;

      if (isMatchedAnswer) {
        const { input } = useCaseInput;
        const { values } = input;

        submissionAnswer.updateValues(
          values.map((value) => SingleMultipleChoiceAnswerValue.create(value).getValue()),
        );

        const hasNotAnsweredBefore = !state.answeredQuestionIds.includes(
          `${useCaseInput.id.toString()}_${useCaseInput.input.questionId}_${
            useCaseInput.input.sectionId
          }`,
        );
        if (hasNotAnsweredBefore) {
          updatedState.answeredQuestionIds.push(
            `${useCaseInput.id.toString()}_${useCaseInput.input.questionId}_${
              useCaseInput.input.sectionId
            }`,
          );
        } else {
          const isEmptyAnswers = useCaseInput.input.values.length === 0;
          if (isEmptyAnswers) {
            updatedState.answeredQuestionIds = updatedState.answeredQuestionIds.filter(
              (answeredQuestionId) => {
                const [answerId, questionId, sectionId] = answeredQuestionId.split('_');

                return (
                  `${answerId}_${questionId}_${sectionId}` !==
                  `${useCaseInput.id.toString()}_${useCaseInput.input.questionId}_${
                    useCaseInput.input.sectionId
                  }`
                );
              },
            );
          }
        }
      }

      return submissionAnswer;
    });

    updatedState.submissionAnswers = submissionAnswers;

    return updatedState;
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.ERROR };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      updateAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateFillInBlankAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as UpdateFillInBlankAnswerUseCaseInput;

    const updatedState: SubmissionPageState = {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
    };

    const submissionAnswers = state.submissionAnswers.map((submissionAnswer) => {
      const isMatchedGroupAnswer =
        submissionAnswer instanceof FillInBlankGroupAnswer &&
        submissionAnswer.answers.some((answer) => answer.id.toString() === useCaseInput.id);

      if (isMatchedGroupAnswer) {
        const updatedFillInBlankAnswer = submissionAnswer.answers.find(
          (answer) => answer.id.toString() === useCaseInput.id,
        );

        const { input } = useCaseInput;
        const { value } = input;

        updatedFillInBlankAnswer.updateValue(FillInBlankAnswerValue.create(value).getValue());
        submissionAnswer.updateAnswers(updatedFillInBlankAnswer);

        const hasNotAnsweredBefore = !state.answeredQuestionIds.includes(
          `${useCaseInput.input.answerBlankId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`,
        );
        if (hasNotAnsweredBefore) {
          updatedState.answeredQuestionIds.push(
            `${useCaseInput.input.answerBlankId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`,
          );
        } else {
          const isEmptyAnswer =
            !useCaseInput.input.value?.text || useCaseInput.input.value.text === '';

          if (isEmptyAnswer) {
            updatedState.answeredQuestionIds = updatedState.answeredQuestionIds.filter(
              (answeredQuestion) => {
                const [answerBlankId, questionId, sectionId] = answeredQuestion.split('_');
                return (
                  `${answerBlankId}_${questionId}_${sectionId}` !==
                  `${useCaseInput.input.answerBlankId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`
                );
              },
            );
          }
        }
      }

      return submissionAnswer;
    });

    updatedState.submissionAnswers = submissionAnswers;
    return updatedState;
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.ERROR };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      updateAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateMatchingAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as UpdateMatchingAnswerUseCaseInput;

    const updatedState: SubmissionPageState = {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
    };

    const submissionAnswers = state.submissionAnswers.map((submissionAnswer) => {
      const isMatchedGroupAnswer =
        submissionAnswer instanceof MatchingAnswerGroup &&
        submissionAnswer.answers.some((answer) => answer.id.toString() === useCaseInput.id);

      if (isMatchedGroupAnswer) {
        const updatedMatchingAnswer = submissionAnswer.answers.find(
          (answer) => answer.id.toString() === useCaseInput.id,
        );

        const { input } = useCaseInput;
        const { value } = input;

        updatedMatchingAnswer.updateValue(MatchingAnswerValue.create(value).getValue());

        submissionAnswer.updateAnswers(updatedMatchingAnswer);
        const hasNotAnsweredBefore = !state.answeredQuestionIds.includes(
          `${useCaseInput.input.subQuestionId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`,
        );

        if (hasNotAnsweredBefore) {
          updatedState.answeredQuestionIds.push(
            `${useCaseInput.input.subQuestionId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`,
          );
        } else {
          const isEmptyAnswers =
            !useCaseInput.input.value?.answerId && useCaseInput.input.value?.answerId === '';
          if (isEmptyAnswers) {
            updatedState.answeredQuestionIds = updatedState.answeredQuestionIds.filter(
              (answeredQuestion) => {
                const [subQuestionId, questionId, sectionId] = answeredQuestion.split('_');

                return (
                  `${subQuestionId}_${questionId}_${sectionId}` !==
                  `${useCaseInput.input.subQuestionId}_${useCaseInput.input.questionId}_${useCaseInput.input.sectionId}`
                );
              },
            );
          }
        }
      }

      return submissionAnswer;
    });

    updatedState.submissionAnswers = submissionAnswers;

    return updatedState;
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateAnswerStatus: RequestStatus.ERROR };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      selectedFile: null,
      updateAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleGetQuestionReleasesUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    QuestionReleases.sortQuestionsWithIndexAndSection(payload);

    return {
      ...state,
      questionReleases: payload,
      getQuestionReleasesStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPageStatus.ERROR,
      error,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPageStatus.RESET,
    };
  },
};

export const handleDeleteSubmissionAnswerFile = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      deleteSubmissionAnswerFileStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { answer } = action.payload as DeleteEssayAnswerFileUseCaseOutput;
    const { answeredQuestionIds } = state;

    let updatedAnsweredQuestionIds: string[];
    if (answer.isSkipped()) {
      updatedAnsweredQuestionIds = answeredQuestionIds.filter(
        (questionId) =>
          questionId !== `${answer.id.toString()}_${answer.questionId}_${answer.sectionId}`,
      );
    }

    const updatedAnswerList = updateAnswerList(state.submissionAnswers, answer);

    return {
      ...state,
      deleteSubmissionAnswerFileStatus: SubmissionPageStatus.SUCCESS,
      submissionAnswers: updatedAnswerList,
      createSubmissionAnswerFileResponse: null,
      answeredQuestionIds: updatedAnsweredQuestionIds
        ? updatedAnsweredQuestionIds
        : answeredQuestionIds,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return { ...state, deleteSubmissionAnswerFileStatus: SubmissionPageStatus.ERROR, error: error };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      deleteSubmissionAnswerFileStatus: SubmissionPageStatus.RESET,
    };
  },
};

export const handleUpdateSelectedFile = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    return {
      ...state,
      selectedFile: payload,
    };
  },
};

export const handleCreateSubmissionAnswerFileStorage = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createSubmissionAnswerFileStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      createSubmissionAnswerFileStatus: SubmissionPageStatus.SUCCESS,
      createSubmissionAnswerFileResponse: action.payload as CreatedFileStorage,
      selectedFile: action.payload as CreatedFileStorage,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createSubmissionAnswerFileStatus: SubmissionPageStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createSubmissionAnswerFileStatus: SubmissionPageStatus.RESET,
      createSubmissionAnswerFileResponse: null,
    };
  },
};

export const handlePausePracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      isCountingDown: false,
      pausePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      pausePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      pausePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleResumePracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      isCountingDown: true,
      resumePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      resumePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      resumePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleSubmitPracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;

    const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
    return {
      ...state,
      submission: newSubmission,
      submitSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleSubmitHomeworkSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;

    const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
    return {
      ...state,
      submission: newSubmission,
      submitSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleSubmitExaminationSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;

    const newSubmission = onUpdateStatusSubmission(submission, submissionStatus.SUBMITTED);
    return {
      ...state,
      submission: newSubmission,
      submitSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleSetIsEndTimePracticeSubmission = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      setIsEndTimePracticeSubmissionStatus: action.payload,
    };
  },
};

export const handleGetSubmissionElapsedTime = {
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      recordedElapsedTime: action.payload,
      getRecordedElapsedTimeStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

export const handleGetFormReleaseBySubmission = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { submission, assignment, formRelease } = action.payload;
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.SUCCESS,
      submission,
      selectedSection: formRelease.sections[state.selectedSectionIndex],
      assignment,
      formRelease,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getFormReleaseBySubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleSetIsOpenEndTimeModal = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      isOpenEndTimeModal: action.payload,
    };
  },
};

export const handleSetIsAutoPlayAudioSection = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      isAutoPlayAudioSection: action.payload,
    };
  },
};

export const handleUpdateHomeworkSubmissionUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      updateHomeworkSubmissionStatus: RequestStatus.EXECUTE,
      currentPlayingAudio: payload?.input?.audioPlayStats
        ? {
            id: payload.input.audioPlayStats.audioFileId,
          }
        : undefined,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateHomeworkSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updateHomeworkSubmissionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateHomeworkSubmissionStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateExaminationSubmissionUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      updateExaminationSubmissionStatus: RequestStatus.EXECUTE,
      currentPlayingAudio: payload?.input?.audioPlayStats
        ? {
            id: payload.input.audioPlayStats.audioFileId,
          }
        : undefined,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updateExaminationSubmissionStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updateExaminationSubmissionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateExaminationSubmissionStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdatePracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    return {
      ...state,
      updatePracticeSubmissionStatus: RequestStatus.EXECUTE,
      currentPlayingAudio: payload?.input?.audioPlayStats
        ? {
            id: payload.input.audioPlayStats.audioFileId,
          }
        : undefined,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return { ...state, updatePracticeSubmissionStatus: RequestStatus.SUCCESS };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updatePracticeSubmissionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updatePracticeSubmissionStatus: RequestStatus.RESET,
    };
  },
};

export const handleGetSubmissionActivityUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getSubmissionActivityStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      submissionActivity: action.payload,
      getSubmissionActivityStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getSubmissionActivityStatus: SubmissionPageStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getSubmissionActivityStatus: SubmissionPageStatus.RESET,
    };
  },
};
export const handleUpdateCurrentPlayingAudio = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      currentPlayingAudio: payload.audioFile,
    };
  },
};

export const handleUpdateSubmissionAudios = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    const updatedAudios = state.submissionAudios ? [...state.submissionAudios] : [];

    if (!payload?.id) {
      return {
        ...state,
        submissionAudios: updatedAudios,
      };
    }

    const existingAudioIndex = updatedAudios.findIndex(
      (submissionAudio) => submissionAudio.id === payload.id,
    );

    if (existingAudioIndex !== -1) {
      updatedAudios[existingAudioIndex] = {
        ...updatedAudios[existingAudioIndex],
        ...payload,
      };
    } else {
      updatedAudios.push(payload);
    }

    return {
      ...state,
      submissionAudios: updatedAudios,
      submissionAudiosVersion: state.submissionAudiosVersion || 0 + 1,
    };
  },
};
export const handleUpdateDraftEssayAnswerUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    let updatingDraftEssayAnswer = updateDraftEssayAnswer({
      draftEssayAnswers: state.draftEssayAnswers,
      submissionId: payload.submissionId,
      questionId: payload.questionId,
      content: payload.content,
    });

    return {
      ...state,
      draftEssayAnswers: updatingDraftEssayAnswer,
      updateDraftEssayAnswerStatus: RequestStatus.EXECUTE,
    };
  },
};

export const handleFindDraftEssayAnswerUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      findDraftEssayAnswerStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    let updatingDraftEssayAnswer = updateDraftEssayAnswer({
      draftEssayAnswers: state.draftEssayAnswers,
      submissionId: payload.submissionId,
      questionId: payload.questionId,
      content: payload.content,
    });

    return {
      ...state,
      draftEssayAnswers: updatingDraftEssayAnswer,
      findDraftEssayAnswerStatus: RequestStatus.SUCCESS,
    };
  },
};

export const handleSetIsOpenSwitchSectionWithAudioModal = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      isConfirmSwitchSection: action.payload?.isConfirmSwitchSection,
    };
  },
};
