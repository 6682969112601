import {
  SubmissionSummary,
  EssayAnswer,
  MultipleChoiceAnswer,
  PracticeAssignment,
  SingleChoiceAnswer,
  FillInBlankGroupAnswer,
  File,
  HomeworkAssignment,
  ExaminationAssignment,
  MatchingAnswerGroup,
} from '@module/assignment';
import {
  AudioFile,
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
  SectionSummary,
} from '@module/form';
import { RootReducerState } from '../root.reducer';
import { SubmissionModalStatus, SubmissionPageStatus } from './submission.reducer';
import { CreatedFileStorage } from '@module/file-storage';
import { RequestStatus } from '@lib/plugin-redux-core';

export const getAssignmentSelector = (
  state: RootReducerState,
): PracticeAssignment | HomeworkAssignment | ExaminationAssignment => {
  return state.submissionPage.assignment;
};

export const getAssignmentStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getAssignmentStatus;
};

export const getFormReleaseSelector = (state: RootReducerState): FormRelease => {
  return state.submissionPage.formRelease;
};

export const getSectionSelectedSelector = (state: RootReducerState): SectionSummary => {
  return state.submissionPage.selectedSection;
};

export const updateSectionSelectedStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.updateSelectedSectionStatus;
};

export const getSectionSelectedIndexSelector = (state: RootReducerState): number => {
  return state.submissionPage.selectedSectionIndex;
};

export const getQuestionReleasesStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.getQuestionReleasesStatus;
};

export const getQuestionReleasesSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => {
  return state.submissionPage.questionReleases;
};

export const getSubmissionSelector = (state: RootReducerState): SubmissionSummary => {
  return state.submissionPage.submission;
};

export const getSubmissionStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getSubmissionStatus;
};

export const getAnswersStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getAnswersStatus;
};

export const submissionAnswersSelector = (
  state: RootReducerState,
): (
  | EssayAnswer
  | SingleChoiceAnswer
  | MultipleChoiceAnswer
  | FillInBlankGroupAnswer
  | MatchingAnswerGroup
)[] => {
  return state.submissionPage.submissionAnswers;
};

export const selectedFileSelector = (state: RootReducerState): CreatedFileStorage | File =>
  state.submissionPage.selectedFile as CreatedFileStorage | File;

export const resumePracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.resumePracticeSubmissionStatus;
};

export const loadingResumePracticeSubmissionSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isLoadingResumePracticeSubmission;
};

export const createAnswerStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.createAnswerStatus;
};

export const updateAnswerStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.submissionPage.updateAnswerStatus;
};

export const pausePracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.pausePracticeSubmissionStatus;
};

export const submitSubmissionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.submissionPage.submitSubmissionStatus;
};

export const questionAnsweredSelector = (
  state: RootReducerState,
): {
  sectionId: string;
  questionId: string;
  submissionId: string;
}[] => {
  return state.submissionPage.questionAnswered;
};

export const setSubmitPracticeSubmissionTimeStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.setSubmitPracticeSubmissionTimeStatus;
};

export const getStopCountDownSubmissionStatusSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isCountingDown;
};

export const setIsEndTimePracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionModalStatus => {
  return state.submissionPage.setIsEndTimePracticeSubmissionStatus;
};

export const createSubmissionAnswerFileSelector = (state: RootReducerState): CreatedFileStorage =>
  state.submissionPage.createSubmissionAnswerFileResponse;
export const createSubmissionAnswerFileStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => state.submissionPage.createSubmissionAnswerFileStatus;

export const uploadAudioStatusSelector = (state: RootReducerState): SubmissionPageStatus =>
  state.submissionPage.uploadAudioStatus;

export const deleteSubmissionAnswerFileStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.deleteSubmissionAnswerFileStatus;
};

export const getFormReleaseBySubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.getFormReleaseBySubmissionStatus;
};

export const getIsOpenEndTimeModalSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isOpenEndTimeModal;
};

export const getTimerStorageSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.getRecordedElapsedTimeStatus;
};

export const getRecordedElapsedTimeSelector = (state: RootReducerState): number => {
  return state.submissionPage.recordedElapsedTime;
};

export const isAutoPlayAudioSection = (state: RootReducerState): boolean => {
  return state.submissionPage.isAutoPlayAudioSection;
};

export const updateHomeworkSubmissionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.submissionPage.updateHomeworkSubmissionStatus;
};

export const getCurrentPlayingAudioSelector = (state: RootReducerState): any => {
  return state.submissionPage.currentPlayingAudio;
};
