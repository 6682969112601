import { createReducer } from '@lib/plugin-redux-core';
import {
  PAUSE_PRACTICE_SUBMISSION_USE_CASE,
  RESUME_PRACTICE_SUBMISSION_USE_CASE,
  GET_ANSWERS_USE_CASE,
  SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  UPDATE_ESSAY_ANSWER_USE_CASE,
  DELETE_ESSAY_ANSWER_FILE_USE_CASE,
  GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
  GET_RECORDED_ELAPSED_TIME_USE_CASE,
  SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
  SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
  UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
  UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
  GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE,
  UPDATE_MATCHING_ANSWER_USE_CASE,
  FIND_DRAFT_ESSAY_ANSWER_USE_CASE,
  UPDATE_PRACTICE_SUBMISSION_USE_CASE,
  UPDATE_DRAFT_ESSAY_ANSWER_USE_CASE,
  UPLOAD_ANSWER_AUDIO_USE_CASE,
} from '@module/assignment';
import { FIND_QUESTION_RELEASES_USE_CASE } from '@module/form';
import {
  CHANGE_SECTION_SELECTED_USE_CASE,
  SET_IS_AUTO_PLAY_AUDIO_SECTION,
  SET_IS_END_TIME_PRACTICE_SUBMISSION,
  SET_IS_OPEN_END_TIME_MODAL,
  UPDATE_SELECTED_FILE,
  UPDATE_CURRENT_PLAYING_AUDIO,
  UPDATE_SUBMISSION_AUDIOS,
  SET_IS_CONFIRM_SWITCH_SECTION_MODAL,
} from 'redux/root.action';
import { CREATE_SUBMISSION_ANSWER_FILE_STORAGE_USE_CASE } from '@module/file-storage';
import { initialState } from './submission.state';
import {
  handleChangeSectionUseCase,
  handleGetQuestionReleasesUseCase,
  handlePausePracticeSubmissionUseCase,
  handleResumePracticeSubmissionUseCase,
  handleGetAnswersUseCase,
  handleUpdateEssayAnswer,
  handleDeleteSubmissionAnswerFile,
  handleCreateSubmissionAnswerFileStorage,
  handleUpdateSelectedFile,
  handleSubmitPracticeSubmissionUseCase,
  handleSubmitHomeworkSubmissionUseCase,
  handleSubmitExaminationSubmissionUseCase,
  handleSetIsEndTimePracticeSubmission,
  handleGetSubmissionElapsedTime,
  handleGetFormReleaseBySubmission,
  handleSetIsOpenEndTimeModal,
  handleUpdateFillInBlankAnswer,
  handleUpdateSingleChoiceAnswer,
  handleUpdateMultipleChoiceAnswer,
  handleSetIsAutoPlayAudioSection,
  handleUpdateCurrentPlayingAudio,
  handleUpdateHomeworkSubmissionUseCase,
  handleUpdateExaminationSubmissionUseCase,
  handleUpdateSubmissionAudios,
  handleGetSubmissionActivityUseCase,
  handleUpdateMatchingAnswer,
  handleFindDraftEssayAnswerUseCase,
  handleUpdatePracticeSubmissionUseCase,
  handleUpdateDraftEssayAnswerUseCase,
  handleSetIsOpenSwitchSectionWithAudioModal,
  handleUploadAnswerAudio,
} from './submission.handler';

export const enum SubmissionPageStatus {
  EXECUTE = 'EXECUTE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RESET = 'RESET',
}

export const enum SubmissionModalStatus {
  ACTIVE = 'ACTIVE',
  DISABLE = 'DISABLE',
}

export const submissionReducer = createReducer(initialState, [
  {
    name: CHANGE_SECTION_SELECTED_USE_CASE,
    ...handleChangeSectionUseCase,
  },
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    ...handleGetQuestionReleasesUseCase,
  },
  {
    name: PAUSE_PRACTICE_SUBMISSION_USE_CASE,
    ...handlePausePracticeSubmissionUseCase,
  },
  {
    name: RESUME_PRACTICE_SUBMISSION_USE_CASE,
    ...handleResumePracticeSubmissionUseCase,
  },
  {
    name: GET_ANSWERS_USE_CASE,
    ...handleGetAnswersUseCase,
  },
  {
    name: UPDATE_ESSAY_ANSWER_USE_CASE,
    ...handleUpdateEssayAnswer,
  },
  {
    name: UPLOAD_ANSWER_AUDIO_USE_CASE,
    ...handleUploadAnswerAudio,
  },
  {
    name: UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    ...handleUpdateSingleChoiceAnswer,
  },
  {
    name: UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    ...handleUpdateMultipleChoiceAnswer,
  },
  {
    name: UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    ...handleUpdateFillInBlankAnswer,
  },
  {
    name: UPDATE_MATCHING_ANSWER_USE_CASE,
    ...handleUpdateMatchingAnswer,
  },
  {
    name: DELETE_ESSAY_ANSWER_FILE_USE_CASE,
    ...handleDeleteSubmissionAnswerFile,
  },
  {
    name: CREATE_SUBMISSION_ANSWER_FILE_STORAGE_USE_CASE,
    ...handleCreateSubmissionAnswerFileStorage,
  },
  {
    name: UPDATE_SELECTED_FILE,
    ...handleUpdateSelectedFile,
  },
  {
    name: SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
    ...handleSubmitPracticeSubmissionUseCase,
  },
  {
    name: SUBMIT_HOMEWORK_SUBMISSION_USE_CASE,
    ...handleSubmitHomeworkSubmissionUseCase,
  },
  {
    name: SUBMIT_EXAMINATION_SUBMISSION_USE_CASE,
    ...handleSubmitExaminationSubmissionUseCase,
  },
  {
    name: SET_IS_END_TIME_PRACTICE_SUBMISSION,
    ...handleSetIsEndTimePracticeSubmission,
  },
  {
    name: GET_RECORDED_ELAPSED_TIME_USE_CASE,
    ...handleGetSubmissionElapsedTime,
  },
  {
    name: GET_FORM_RELEASE_BY_SUBMISSION_USE_CASE,
    ...handleGetFormReleaseBySubmission,
  },
  {
    name: SET_IS_OPEN_END_TIME_MODAL,
    ...handleSetIsOpenEndTimeModal,
  },
  {
    name: SET_IS_AUTO_PLAY_AUDIO_SECTION,
    ...handleSetIsAutoPlayAudioSection,
  },
  {
    name: UPDATE_CURRENT_PLAYING_AUDIO,
    ...handleUpdateCurrentPlayingAudio,
  },
  {
    name: UPDATE_HOMEWORK_SUBMISSION_USE_CASE,
    ...handleUpdateHomeworkSubmissionUseCase,
  },
  {
    name: UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
    ...handleUpdateExaminationSubmissionUseCase,
  },
  {
    name: UPDATE_PRACTICE_SUBMISSION_USE_CASE,
    ...handleUpdatePracticeSubmissionUseCase,
  },
  {
    name: UPDATE_SUBMISSION_AUDIOS,
    ...handleUpdateSubmissionAudios,
  },
  { name: GET_SUBMISSION_ACTIVITY_BY_ID_USE_CASE, ...handleGetSubmissionActivityUseCase },
  {
    name: FIND_DRAFT_ESSAY_ANSWER_USE_CASE,
    ...handleFindDraftEssayAnswerUseCase,
  },
  {
    name: SET_IS_CONFIRM_SWITCH_SECTION_MODAL,
    ...handleSetIsOpenSwitchSectionWithAudioModal,
  },
  {
    name: UPDATE_DRAFT_ESSAY_ANSWER_USE_CASE,
    ...handleUpdateDraftEssayAnswerUseCase,
  },
]);
