import {
  ExaminationAssignment,
  HomeworkAssignment,
  PracticeAssignment,
  SubmissionSummary,
} from '@module/assignment';
import { RootReducerState } from '../../redux/root.reducer';
import { AssignmentDetailPageStatus } from './assignment-detail.reducer';
import { User } from '@module/user';
import { RequestStatus } from '@lib/plugin-redux-core';

export const getAssignmentSelector = (
  state: RootReducerState,
): PracticeAssignment | HomeworkAssignment | ExaminationAssignment => {
  return state.assignmentDetailPage.assignment;
};

export const getAssignmentStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.assignmentDetailPage.getAssignmentStatus;
};

export const getSubmissionsSelector = (state: RootReducerState): SubmissionSummary[] => {
  return state.assignmentDetailPage.submissions;
};

export const createAttendeeStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.createAttendeeStatus;
};

export const getAttendeeIdSelector = (state: RootReducerState): string => {
  return state.assignmentDetailPage.attendeeId;
};

export const createSubmissionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.assignmentDetailPage.createSubmissionStatus;
};

export const newSubmissionIdSelector = (state: RootReducerState): string => {
  return state.assignmentDetailPage.newSubmissionId;
};

export const getAssignerSelector = (state: RootReducerState): User => {
  return state.assignmentDetailPage.assigner;
};

export const isLoadingVerifyPasswordStatusSelector = (state: RootReducerState): boolean => {
  return state.assignmentDetailPage.isLoadingVerifyPassword;
};

export const verifyPasswordStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.verifyPasswordStatus;
};
