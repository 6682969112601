import { Action, RequestStatus } from '@lib/plugin-redux-core';
import { AssignmentDetailPageState, AssignmentDetailPageStatus } from './assignment-detail.reducer';

export const handleGetResponderAssignmentUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      getAssignmentStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { assignment, assigner, submissions } = action.payload;
    return {
      ...state,
      assignment,
      assigner,
      submissions,
      getAssignmentStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAssignmentStatus: RequestStatus.ERROR,
    };
  },
};

export const handleCreateAttendeeUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createAttendeeStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      attendeeId: action.payload.id,
      createAttendeeStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createAttendeeStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};

export const handleCreatePracticeSubmissionUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      createSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleCreateHomeworkSubmissionUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      createSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleCreateExaminationSubmissionUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      createSubmissionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createSubmissionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleVerifyPasswordUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      isLoadingVerifyPassword: true,
      verifyPasswordStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      isLoadingVerifyPassword: false,
      verifyPasswordStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingVerifyPassword: false,
      verifyPasswordStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};
